<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>
 <div class="imgBox6 min-w-1400">
      <div class="img6 max-w-1400">
        <img :src="importedImg3" :alt="altText" class="imgItem6" />
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <!-- <img :src="importedImg3" :alt="altText" class="imgItem3" /> -->
        <ul class="imageList">
          <li> <img :src="importedImg6" :alt="altText" class="imgItem3" /></li>
             <li> <img :src="importedImg7" :alt="altText" class="imgItem3" /></li>
                <li> <img :src="importedImg8" :alt="altText" class="imgItem3" /></li>
                 <li class="imgLi"> <img :src="importedImg9" :alt="altText" class="imgItem3" /></li>
        </ul>
      </div>
    </div>
    <div class="imgBox7 min-w-1400">
      <div class="img7 max-w-1400">
        <img :src="importedImg13" :alt="altText" class="imgItem7" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4 max-w-1400"  >
        <ul>
          <li><img :src="importedImg10" :alt="altText" class="imgItem4" /></li>
           <li><img :src="importedImg11" :alt="altText" class="imgItem4" /></li>
            <li><img :src="importedImg12" :alt="altText" class="imgItem4" /></li>
        </ul>
      </div>
    </div>
    <div class="imgBox5 min-w-1400">
      <div class="img5 max-w-1400">
        <img :src="importedImg5" :alt="altText" class="imgItem5" />
      </div>
    </div>


  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/eliteloan/components/img/1_1.png";
import importedImg2 from "@/page/eliteloan/components/img/1_2.png";
import importedImg3 from "@/page/eliteloan/components/img/1_3.png";
import importedImg4 from "@/page/eliteloan/components/img/1_4.png";
import importedImg5 from "@/page/eliteloan/components/img/1_5.png";
import importedImg6 from "@/page/eliteloan/components/img/1.png";
import importedImg7 from "@/page/eliteloan/components/img/2.png";
import importedImg8 from "@/page/eliteloan/components/img/3.png";
import importedImg9 from "@/page/eliteloan/components/img/4.png";
import importedImg10 from "@/page/eliteloan/components/img/01.png";
import importedImg11 from "@/page/eliteloan/components/img/02.png";
import importedImg12 from "@/page/eliteloan/components/img/03.png";
import importedImg13 from "@/page/eliteloan/components/img/1_6.png";
export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      importedImg6: importedImg6,
      importedImg7: importedImg7,
      importedImg8: importedImg8,
      importedImg9: importedImg9,
      importedImg10: importedImg10,
      importedImg11: importedImg11,
      importedImg12: importedImg12,
      importedImg13: importedImg13,
      altText: "图片",
    };
  },
  //   methods: {
  //   zoomIn(event) {
  //     // 鼠标悬停时放大图片
  //     event.currentTarget.querySelector(".imgItem4").style.transform = "scale(1.1)";
  //   },
  //   zoomOut(event) {
  //     // 鼠标离开时恢复原始大小
  //     event.currentTarget.querySelector(".imgItem4").style.transform = "scale(1)";
  //   },
  // },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
/* .imgBox1,
.imgBox2 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
} */
.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,.imgBox5,.imgBox6,.imgBox7
{
  display: flex;
  justify-content: center;
  width: 100%;
}
img {
  display: block;
}
/* .img1,
.img2 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
} */
.img1,.img2,
.img3,.img4,.img5,.img6,.img7{
  margin-left: 45px;
  margin-right: 45px;
}
.imgItem1,.imgItem2 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imageList {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.imageList li {
  margin: 0 5px; /* 控制 li 元素之间的间距 */
  transition: transform 0.3s; /* 添加过渡效果 */
}

.imageList li:hover {
  transform: translateY(-7px); /* 鼠标悬停时向上移动 5px */
}
.imgItem2{
  margin-top: 100px;
  margin-bottom: 100px;
}
.imgItem3 {
  max-width: 100%;
  margin-bottom: 100px;
}
.imgItem4 {
  max-width: 100%;
  margin-bottom: 100px;
  /* transition: transform 0.3s;  */
}
.imgItem5 {
  max-width: 100%;
  margin-bottom: 120px;
}
.imgItem6 {
  max-width: 100%;
  margin-bottom: 50px;
}
.imgItem7 {
  max-width: 100%;
  margin-bottom: 50px;
}
.imgBox4 .img4 ul li {
  margin: 0 5px; /* 控制 li 元素之间的间距 */
  transition: transform 0.3s ease-in-out; /* 添加过渡效果，使用 ease-in-out 缓动函数 */
}
.imgBox4 .img4 ul li:hover {
  transform: translateY(-7px) scale(1.05); /* 鼠标悬停时向上移动 7px 并放大 1.1 倍 */
}

/* .imgItem3 {
  max-width: 100%;
  margin-bottom: 100px;
}
.imgItem4 {
margin-bottom: 100px;
} */
</style>
